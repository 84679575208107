/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfraBrokerRevisionStatusV1ResourcesDto } from './InfraBrokerRevisionStatusV1ResourcesDto';
import {
    InfraBrokerRevisionStatusV1ResourcesDtoFromJSON,
    InfraBrokerRevisionStatusV1ResourcesDtoFromJSONTyped,
    InfraBrokerRevisionStatusV1ResourcesDtoToJSON,
} from './InfraBrokerRevisionStatusV1ResourcesDto';
import type { InstanceStatusV1Dto } from './InstanceStatusV1Dto';
import {
    InstanceStatusV1DtoFromJSON,
    InstanceStatusV1DtoFromJSONTyped,
    InstanceStatusV1DtoToJSON,
} from './InstanceStatusV1Dto';

/**
 * 
 * @export
 * @interface InfraBrokerRevisionStatusV1Dto
 */
export interface InfraBrokerRevisionStatusV1Dto {
    /**
     * 
     * @type {string}
     * @memberof InfraBrokerRevisionStatusV1Dto
     */
    name?: string;
    /**
     * 
     * @type {InfraBrokerRevisionStatusV1ResourcesDto}
     * @memberof InfraBrokerRevisionStatusV1Dto
     */
    resources?: InfraBrokerRevisionStatusV1ResourcesDto;
    /**
     * 
     * @type {string}
     * @memberof InfraBrokerRevisionStatusV1Dto
     */
    status?: InfraBrokerRevisionStatusV1DtoStatusEnum;
    /**
     * 
     * @type {Array<InstanceStatusV1Dto>}
     * @memberof InfraBrokerRevisionStatusV1Dto
     */
    instances?: Array<InstanceStatusV1Dto>;
}


/**
 * @export
 */
export const InfraBrokerRevisionStatusV1DtoStatusEnum = {
    Pending: 'PENDING',
    Healthy: 'HEALTHY',
    Failing: 'FAILING',
    NoInstances: 'NO_INSTANCES',
    Unknown: 'UNKNOWN'
} as const;
export type InfraBrokerRevisionStatusV1DtoStatusEnum = typeof InfraBrokerRevisionStatusV1DtoStatusEnum[keyof typeof InfraBrokerRevisionStatusV1DtoStatusEnum];


/**
 * Check if a given object implements the InfraBrokerRevisionStatusV1Dto interface.
 */
export function instanceOfInfraBrokerRevisionStatusV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfraBrokerRevisionStatusV1DtoFromJSON(json: any): InfraBrokerRevisionStatusV1Dto {
    return InfraBrokerRevisionStatusV1DtoFromJSONTyped(json, false);
}

export function InfraBrokerRevisionStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerRevisionStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'resources': !exists(json, 'resources') ? undefined : InfraBrokerRevisionStatusV1ResourcesDtoFromJSON(json['resources']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'instances': !exists(json, 'instances') ? undefined : ((json['instances'] as Array<any>).map(InstanceStatusV1DtoFromJSON)),
    };
}

export function InfraBrokerRevisionStatusV1DtoToJSON(value?: InfraBrokerRevisionStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'resources': InfraBrokerRevisionStatusV1ResourcesDtoToJSON(value.resources),
        'status': value.status,
        'instances': value.instances === undefined ? undefined : ((value.instances as Array<any>).map(InstanceStatusV1DtoToJSON)),
    };
}

