/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto {
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto
     */
    strategy: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoStrategyEnum;
}


/**
 * @export
 */
export const GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoStrategyEnum = {
    RollingUpdate: 'ROLLING_UPDATE',
    RedBlack: 'RED_BLACK',
    Recreate: 'RECREATE'
} as const;
export type GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoStrategyEnum = typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoStrategyEnum[keyof typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoStrategyEnum];


/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "strategy" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'strategy': json['strategy'],
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateRolloutDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'strategy': value.strategy,
    };
}

