/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionStatusV1InstancesInnerEventsInnerDto
 */
export interface RevisionStatusV1InstancesInnerEventsInnerDto {
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerEventsInnerDto
     */
    firstTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerEventsInnerDto
     */
    lastTimestamp: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerEventsInnerDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerEventsInnerDto
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerEventsInnerDto
     */
    type: string;
}

/**
 * Check if a given object implements the RevisionStatusV1InstancesInnerEventsInnerDto interface.
 */
export function instanceOfRevisionStatusV1InstancesInnerEventsInnerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstTimestamp" in value;
    isInstance = isInstance && "lastTimestamp" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function RevisionStatusV1InstancesInnerEventsInnerDtoFromJSON(json: any): RevisionStatusV1InstancesInnerEventsInnerDto {
    return RevisionStatusV1InstancesInnerEventsInnerDtoFromJSONTyped(json, false);
}

export function RevisionStatusV1InstancesInnerEventsInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionStatusV1InstancesInnerEventsInnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstTimestamp': json['firstTimestamp'],
        'lastTimestamp': json['lastTimestamp'],
        'message': json['message'],
        'reason': json['reason'],
        'type': json['type'],
    };
}

export function RevisionStatusV1InstancesInnerEventsInnerDtoToJSON(value?: RevisionStatusV1InstancesInnerEventsInnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstTimestamp': value.firstTimestamp,
        'lastTimestamp': value.lastTimestamp,
        'message': value.message,
        'reason': value.reason,
        'type': value.type,
    };
}

