/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionDescriptionDto } from './GithubRepositoryTemplateSpecV1DefinitionDescriptionDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionDescriptionDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionDescriptionDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionDescriptionDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionDescriptionDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionDto {
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionDto
     */
    serviceTemplate: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionDescriptionDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionDto
     */
    description: GithubRepositoryTemplateSpecV1DefinitionDescriptionDto;
}

/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serviceTemplate" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionDto {
    return GithubRepositoryTemplateSpecV1DefinitionDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceTemplate': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoFromJSON(json['serviceTemplate']),
        'description': GithubRepositoryTemplateSpecV1DefinitionDescriptionDtoFromJSON(json['description']),
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceTemplate': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateDtoToJSON(value.serviceTemplate),
        'description': GithubRepositoryTemplateSpecV1DefinitionDescriptionDtoToJSON(value.description),
    };
}

