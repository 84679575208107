/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecretGroupSpecV1DataValueDto } from './SecretGroupSpecV1DataValueDto';
import {
    SecretGroupSpecV1DataValueDtoFromJSON,
    SecretGroupSpecV1DataValueDtoFromJSONTyped,
    SecretGroupSpecV1DataValueDtoToJSON,
} from './SecretGroupSpecV1DataValueDto';

/**
 * 
 * @export
 * @interface SecretGroupSpecV1Dto
 */
export interface SecretGroupSpecV1Dto {
    /**
     * 
     * @type {{ [key: string]: SecretGroupSpecV1DataValueDto | undefined; }}
     * @memberof SecretGroupSpecV1Dto
     */
    data: { [key: string]: SecretGroupSpecV1DataValueDto | undefined; };
}

/**
 * Check if a given object implements the SecretGroupSpecV1Dto interface.
 */
export function instanceOfSecretGroupSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function SecretGroupSpecV1DtoFromJSON(json: any): SecretGroupSpecV1Dto {
    return SecretGroupSpecV1DtoFromJSONTyped(json, false);
}

export function SecretGroupSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretGroupSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': (mapValues(json['data'], SecretGroupSpecV1DataValueDtoFromJSON)),
    };
}

export function SecretGroupSpecV1DtoToJSON(value?: SecretGroupSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': (mapValues(value.data, SecretGroupSpecV1DataValueDtoToJSON)),
    };
}

