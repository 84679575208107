/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DatabaseTypeV1Dto = {
    DatabasePostgresUnmanaged: 'DATABASE_POSTGRES_UNMANAGED'
} as const;
export type DatabaseTypeV1Dto = typeof DatabaseTypeV1Dto[keyof typeof DatabaseTypeV1Dto];


export function DatabaseTypeV1DtoFromJSON(json: any): DatabaseTypeV1Dto {
    return DatabaseTypeV1DtoFromJSONTyped(json, false);
}

export function DatabaseTypeV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseTypeV1Dto {
    return json as DatabaseTypeV1Dto;
}

export function DatabaseTypeV1DtoToJSON(value?: DatabaseTypeV1Dto | null): any {
    return value as any;
}

