/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevisionTemplateSpecV1ObservabilityMetricsDto
 */
export interface RevisionTemplateSpecV1ObservabilityMetricsDto {
    /**
     * 
     * @type {boolean}
     * @memberof RevisionTemplateSpecV1ObservabilityMetricsDto
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof RevisionTemplateSpecV1ObservabilityMetricsDto
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionTemplateSpecV1ObservabilityMetricsDto
     */
    path?: string;
}

/**
 * Check if a given object implements the RevisionTemplateSpecV1ObservabilityMetricsDto interface.
 */
export function instanceOfRevisionTemplateSpecV1ObservabilityMetricsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function RevisionTemplateSpecV1ObservabilityMetricsDtoFromJSON(json: any): RevisionTemplateSpecV1ObservabilityMetricsDto {
    return RevisionTemplateSpecV1ObservabilityMetricsDtoFromJSONTyped(json, false);
}

export function RevisionTemplateSpecV1ObservabilityMetricsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionTemplateSpecV1ObservabilityMetricsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'path': !exists(json, 'path') ? undefined : json['path'],
    };
}

export function RevisionTemplateSpecV1ObservabilityMetricsDtoToJSON(value?: RevisionTemplateSpecV1ObservabilityMetricsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'port': value.port,
        'path': value.path,
    };
}

