/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDto';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto {
    /**
     * 
     * @type {boolean}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
     */
    type?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoTypeEnum;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
     */
    http?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
     */
    tcp?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
     */
    exec?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDto;
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto
     */
    config?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDto;
}


/**
 * @export
 */
export const GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoTypeEnum = {
    Http: 'HTTP',
    Tcp: 'TCP',
    Exec: 'EXEC'
} as const;
export type GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoTypeEnum = typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoTypeEnum[keyof typeof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoTypeEnum];


/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'http': !exists(json, 'http') ? undefined : GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDtoFromJSON(json['http']),
        'tcp': !exists(json, 'tcp') ? undefined : GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDtoFromJSON(json['tcp']),
        'exec': !exists(json, 'exec') ? undefined : GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoFromJSON(json['exec']),
        'config': !exists(json, 'config') ? undefined : GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoFromJSON(json['config']),
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'type': value.type,
        'http': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckHttpDtoToJSON(value.http),
        'tcp': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckTcpDtoToJSON(value.tcp),
        'exec': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckExecDtoToJSON(value.exec),
        'config': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateHealthCheckConfigDtoToJSON(value.config),
    };
}

