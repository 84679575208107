/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecretFileMountSpecV1Dto
 */
export interface SecretFileMountSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof SecretFileMountSpecV1Dto
     */
    mountPath: string;
    /**
     * 
     * @type {string}
     * @memberof SecretFileMountSpecV1Dto
     */
    secretGroup: string;
    /**
     * 
     * @type {string}
     * @memberof SecretFileMountSpecV1Dto
     */
    fileFormat?: SecretFileMountSpecV1DtoFileFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof SecretFileMountSpecV1Dto
     */
    secretKey?: string;
}


/**
 * @export
 */
export const SecretFileMountSpecV1DtoFileFormatEnum = {
    Json: 'JSON',
    Properties: 'PROPERTIES',
    Yaml: 'YAML'
} as const;
export type SecretFileMountSpecV1DtoFileFormatEnum = typeof SecretFileMountSpecV1DtoFileFormatEnum[keyof typeof SecretFileMountSpecV1DtoFileFormatEnum];


/**
 * Check if a given object implements the SecretFileMountSpecV1Dto interface.
 */
export function instanceOfSecretFileMountSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "mountPath" in value;
    isInstance = isInstance && "secretGroup" in value;

    return isInstance;
}

export function SecretFileMountSpecV1DtoFromJSON(json: any): SecretFileMountSpecV1Dto {
    return SecretFileMountSpecV1DtoFromJSONTyped(json, false);
}

export function SecretFileMountSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretFileMountSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mountPath': json['mountPath'],
        'secretGroup': json['secretGroup'],
        'fileFormat': !exists(json, 'fileFormat') ? undefined : json['fileFormat'],
        'secretKey': !exists(json, 'secretKey') ? undefined : json['secretKey'],
    };
}

export function SecretFileMountSpecV1DtoToJSON(value?: SecretFileMountSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mountPath': value.mountPath,
        'secretGroup': value.secretGroup,
        'fileFormat': value.fileFormat,
        'secretKey': value.secretKey,
    };
}

