/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { UnmanagedPostgresDatabaseBackupSpecV1Dto } from './UnmanagedPostgresDatabaseBackupSpecV1Dto';
import {
    instanceOfUnmanagedPostgresDatabaseBackupSpecV1Dto,
    UnmanagedPostgresDatabaseBackupSpecV1DtoFromJSON,
    UnmanagedPostgresDatabaseBackupSpecV1DtoFromJSONTyped,
    UnmanagedPostgresDatabaseBackupSpecV1DtoToJSON,
} from './UnmanagedPostgresDatabaseBackupSpecV1Dto';

/**
 * @type DatabaseBackupSpecV1Dto
 * Database backup specification.
 * @export
 */
export type DatabaseBackupSpecV1Dto = { databaseType: 'DATABASE_POSTGRES_UNMANAGED' } & UnmanagedPostgresDatabaseBackupSpecV1Dto;

export function DatabaseBackupSpecV1DtoFromJSON(json: any): DatabaseBackupSpecV1Dto {
    return DatabaseBackupSpecV1DtoFromJSONTyped(json, false);
}

export function DatabaseBackupSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseBackupSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['databaseType']) {
        case 'DATABASE_POSTGRES_UNMANAGED':
            return {...UnmanagedPostgresDatabaseBackupSpecV1DtoFromJSONTyped(json, true), databaseType: 'DATABASE_POSTGRES_UNMANAGED'};
        default:
            throw new Error(`No variant of DatabaseBackupSpecV1Dto exists with 'databaseType=${json['databaseType']}'`);
    }
}

export function DatabaseBackupSpecV1DtoToJSON(value?: DatabaseBackupSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['databaseType']) {
        case 'DATABASE_POSTGRES_UNMANAGED':
            return UnmanagedPostgresDatabaseBackupSpecV1DtoToJSON(value);
        default:
            throw new Error(`No variant of DatabaseBackupSpecV1Dto exists with 'databaseType=${value['databaseType']}'`);
    }

}

