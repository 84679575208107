/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HarborProjectLabelV1Dto
 */
export interface HarborProjectLabelV1Dto {
    /**
     * The name the label
     * @type {string}
     * @memberof HarborProjectLabelV1Dto
     */
    name: string;
    /**
     * The color the label
     * @type {string}
     * @memberof HarborProjectLabelV1Dto
     */
    colourCode: string;
    /**
     * The description the label
     * @type {string}
     * @memberof HarborProjectLabelV1Dto
     */
    description?: string;
}

/**
 * Check if a given object implements the HarborProjectLabelV1Dto interface.
 */
export function instanceOfHarborProjectLabelV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "colourCode" in value;

    return isInstance;
}

export function HarborProjectLabelV1DtoFromJSON(json: any): HarborProjectLabelV1Dto {
    return HarborProjectLabelV1DtoFromJSONTyped(json, false);
}

export function HarborProjectLabelV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectLabelV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'colourCode': json['colourCode'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function HarborProjectLabelV1DtoToJSON(value?: HarborProjectLabelV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'colourCode': value.colourCode,
        'description': value.description,
    };
}

