/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfraBrokerStatusSpecV1Dto
 */
export interface InfraBrokerStatusSpecV1Dto {
    /**
     * 1 CPU core = 1000 millicores
     * @type {number}
     * @memberof InfraBrokerStatusSpecV1Dto
     */
    maxCpuMillicores: number;
    /**
     * 
     * @type {number}
     * @memberof InfraBrokerStatusSpecV1Dto
     */
    maxMemoryMegabytes: number;
    /**
     * 1 CPU core = 1000 millicores
     * @type {number}
     * @memberof InfraBrokerStatusSpecV1Dto
     */
    allocatableCpuMillicores: number;
    /**
     * 
     * @type {number}
     * @memberof InfraBrokerStatusSpecV1Dto
     */
    allocatableMemoryMegabytes: number;
}

/**
 * Check if a given object implements the InfraBrokerStatusSpecV1Dto interface.
 */
export function instanceOfInfraBrokerStatusSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maxCpuMillicores" in value;
    isInstance = isInstance && "maxMemoryMegabytes" in value;
    isInstance = isInstance && "allocatableCpuMillicores" in value;
    isInstance = isInstance && "allocatableMemoryMegabytes" in value;

    return isInstance;
}

export function InfraBrokerStatusSpecV1DtoFromJSON(json: any): InfraBrokerStatusSpecV1Dto {
    return InfraBrokerStatusSpecV1DtoFromJSONTyped(json, false);
}

export function InfraBrokerStatusSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerStatusSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxCpuMillicores': json['maxCpuMillicores'],
        'maxMemoryMegabytes': json['maxMemoryMegabytes'],
        'allocatableCpuMillicores': json['allocatableCpuMillicores'],
        'allocatableMemoryMegabytes': json['allocatableMemoryMegabytes'],
    };
}

export function InfraBrokerStatusSpecV1DtoToJSON(value?: InfraBrokerStatusSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maxCpuMillicores': value.maxCpuMillicores,
        'maxMemoryMegabytes': value.maxMemoryMegabytes,
        'allocatableCpuMillicores': value.allocatableCpuMillicores,
        'allocatableMemoryMegabytes': value.allocatableMemoryMegabytes,
    };
}

