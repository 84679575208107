/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ObjectRefV1Dto } from './ObjectRefV1Dto';
import {
    ObjectRefV1DtoFromJSON,
    ObjectRefV1DtoFromJSONTyped,
    ObjectRefV1DtoToJSON,
} from './ObjectRefV1Dto';

/**
 * 
 * @export
 * @interface ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto
 */
export interface ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto {
    /**
     * 
     * @type {ObjectRefV1Dto}
     * @memberof ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto
     */
    teamRef: ObjectRefV1Dto;
}

/**
 * Check if a given object implements the ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto interface.
 */
export function instanceOfApplicationQuickstartPipelineSpecV1ApplicationTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teamRef" in value;

    return isInstance;
}

export function ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoFromJSON(json: any): ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto {
    return ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoFromJSONTyped(json, false);
}

export function ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamRef': ObjectRefV1DtoFromJSON(json['teamRef']),
    };
}

export function ApplicationQuickstartPipelineSpecV1ApplicationTemplateDtoToJSON(value?: ApplicationQuickstartPipelineSpecV1ApplicationTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamRef': ObjectRefV1DtoToJSON(value.teamRef),
    };
}

