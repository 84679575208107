/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { APIVersionV1Dto } from './APIVersionV1Dto';
import {
    APIVersionV1DtoFromJSON,
    APIVersionV1DtoFromJSONTyped,
    APIVersionV1DtoToJSON,
} from './APIVersionV1Dto';
import type { ApplicationQuickstartPipelineSpecV1Dto } from './ApplicationQuickstartPipelineSpecV1Dto';
import {
    ApplicationQuickstartPipelineSpecV1DtoFromJSON,
    ApplicationQuickstartPipelineSpecV1DtoFromJSONTyped,
    ApplicationQuickstartPipelineSpecV1DtoToJSON,
} from './ApplicationQuickstartPipelineSpecV1Dto';
import type { ObjectMetaV1Dto } from './ObjectMetaV1Dto';
import {
    ObjectMetaV1DtoFromJSON,
    ObjectMetaV1DtoFromJSONTyped,
    ObjectMetaV1DtoToJSON,
} from './ObjectMetaV1Dto';
import type { PipelineStatusV1Dto } from './PipelineStatusV1Dto';
import {
    PipelineStatusV1DtoFromJSON,
    PipelineStatusV1DtoFromJSONTyped,
    PipelineStatusV1DtoToJSON,
} from './PipelineStatusV1Dto';

/**
 * 
 * @export
 * @interface ApplicationQuickstartPipelineV1Dto
 */
export interface ApplicationQuickstartPipelineV1Dto {
    /**
     * 
     * @type {APIVersionV1Dto}
     * @memberof ApplicationQuickstartPipelineV1Dto
     */
    apiVersion: APIVersionV1Dto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationQuickstartPipelineV1Dto
     */
    kind: ApplicationQuickstartPipelineV1DtoKindEnum;
    /**
     * 
     * @type {ObjectMetaV1Dto}
     * @memberof ApplicationQuickstartPipelineV1Dto
     */
    metadata: ObjectMetaV1Dto;
    /**
     * 
     * @type {ApplicationQuickstartPipelineSpecV1Dto}
     * @memberof ApplicationQuickstartPipelineV1Dto
     */
    spec: ApplicationQuickstartPipelineSpecV1Dto;
    /**
     * 
     * @type {PipelineStatusV1Dto}
     * @memberof ApplicationQuickstartPipelineV1Dto
     */
    status?: PipelineStatusV1Dto;
}


/**
 * @export
 */
export const ApplicationQuickstartPipelineV1DtoKindEnum = {
    ApplicationQuickstartPipeline: 'ApplicationQuickstartPipeline'
} as const;
export type ApplicationQuickstartPipelineV1DtoKindEnum = typeof ApplicationQuickstartPipelineV1DtoKindEnum[keyof typeof ApplicationQuickstartPipelineV1DtoKindEnum];


/**
 * Check if a given object implements the ApplicationQuickstartPipelineV1Dto interface.
 */
export function instanceOfApplicationQuickstartPipelineV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiVersion" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "spec" in value;

    return isInstance;
}

export function ApplicationQuickstartPipelineV1DtoFromJSON(json: any): ApplicationQuickstartPipelineV1Dto {
    return ApplicationQuickstartPipelineV1DtoFromJSONTyped(json, false);
}

export function ApplicationQuickstartPipelineV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationQuickstartPipelineV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiVersion': APIVersionV1DtoFromJSON(json['apiVersion']),
        'kind': json['kind'],
        'metadata': ObjectMetaV1DtoFromJSON(json['metadata']),
        'spec': ApplicationQuickstartPipelineSpecV1DtoFromJSON(json['spec']),
        'status': !exists(json, 'status') ? undefined : PipelineStatusV1DtoFromJSON(json['status']),
    };
}

export function ApplicationQuickstartPipelineV1DtoToJSON(value?: ApplicationQuickstartPipelineV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiVersion': APIVersionV1DtoToJSON(value.apiVersion),
        'kind': value.kind,
        'metadata': ObjectMetaV1DtoToJSON(value.metadata),
        'spec': ApplicationQuickstartPipelineSpecV1DtoToJSON(value.spec),
        'status': PipelineStatusV1DtoToJSON(value.status),
    };
}

