/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The target of the webhook
 * @export
 * @interface HarborProjectWebhookTargetV1Dto
 */
export interface HarborProjectWebhookTargetV1Dto {
    /**
     * The address of the webhook
     * @type {string}
     * @memberof HarborProjectWebhookTargetV1Dto
     */
    address: string;
    /**
     * Should the webhook skip certificate verification
     * @type {boolean}
     * @memberof HarborProjectWebhookTargetV1Dto
     */
    skipCertVerify?: boolean;
    /**
     * The authorization header for the webhook
     * @type {string}
     * @memberof HarborProjectWebhookTargetV1Dto
     */
    authHeader?: string;
}

/**
 * Check if a given object implements the HarborProjectWebhookTargetV1Dto interface.
 */
export function instanceOfHarborProjectWebhookTargetV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;

    return isInstance;
}

export function HarborProjectWebhookTargetV1DtoFromJSON(json: any): HarborProjectWebhookTargetV1Dto {
    return HarborProjectWebhookTargetV1DtoFromJSONTyped(json, false);
}

export function HarborProjectWebhookTargetV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectWebhookTargetV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'skipCertVerify': !exists(json, 'skipCertVerify') ? undefined : json['skipCertVerify'],
        'authHeader': !exists(json, 'authHeader') ? undefined : json['authHeader'],
    };
}

export function HarborProjectWebhookTargetV1DtoToJSON(value?: HarborProjectWebhookTargetV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'skipCertVerify': value.skipCertVerify,
        'authHeader': value.authHeader,
    };
}

