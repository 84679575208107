/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto } from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto';
import {
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoFromJSON,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoFromJSONTyped,
    GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoToJSON,
} from './GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto';

/**
 * 
 * @export
 * @interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto
 */
export interface GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto {
    /**
     * 
     * @type {GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto}
     * @memberof GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto
     */
    metrics: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDto;
}

/**
 * Check if a given object implements the GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto interface.
 */
export function instanceOfGithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metrics" in value;

    return isInstance;
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoFromJSON(json: any): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto {
    return GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoFromJSONTyped(json, false);
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoFromJSON(json['metrics']),
    };
}

export function GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDtoToJSON(value?: GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': GithubRepositoryTemplateSpecV1DefinitionServiceTemplateObservabilityMetricsDtoToJSON(value.metrics),
    };
}

