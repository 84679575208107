/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Compute plan reference data which decides the cpu / memory configuration for the database
 * @export
 * @interface ComputePlanV1Dto
 */
export interface ComputePlanV1Dto {
    /**
     * 
     * @type {string}
     * @memberof ComputePlanV1Dto
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof ComputePlanV1Dto
     */
    readonly cpu: string;
    /**
     * 
     * @type {string}
     * @memberof ComputePlanV1Dto
     */
    readonly memory: string;
}

/**
 * Check if a given object implements the ComputePlanV1Dto interface.
 */
export function instanceOfComputePlanV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "cpu" in value;
    isInstance = isInstance && "memory" in value;

    return isInstance;
}

export function ComputePlanV1DtoFromJSON(json: any): ComputePlanV1Dto {
    return ComputePlanV1DtoFromJSONTyped(json, false);
}

export function ComputePlanV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComputePlanV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'cpu': json['cpu'],
        'memory': json['memory'],
    };
}

export function ComputePlanV1DtoToJSON(value?: ComputePlanV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

