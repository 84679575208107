/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ObjectRefV1Dto } from './ObjectRefV1Dto';
import {
    ObjectRefV1DtoFromJSON,
    ObjectRefV1DtoFromJSONTyped,
    ObjectRefV1DtoToJSON,
} from './ObjectRefV1Dto';

/**
 * 
 * @export
 * @interface ApplicationSpecV1Dto
 */
export interface ApplicationSpecV1Dto {
    /**
     * 
     * @type {ObjectRefV1Dto}
     * @memberof ApplicationSpecV1Dto
     */
    teamRef: ObjectRefV1Dto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSpecV1Dto
     */
    readonly macAppId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSpecV1Dto
     */
    displayName?: string;
}

/**
 * Check if a given object implements the ApplicationSpecV1Dto interface.
 */
export function instanceOfApplicationSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teamRef" in value;

    return isInstance;
}

export function ApplicationSpecV1DtoFromJSON(json: any): ApplicationSpecV1Dto {
    return ApplicationSpecV1DtoFromJSONTyped(json, false);
}

export function ApplicationSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teamRef': ObjectRefV1DtoFromJSON(json['teamRef']),
        'macAppId': !exists(json, 'macAppId') ? undefined : json['macAppId'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
    };
}

export function ApplicationSpecV1DtoToJSON(value?: ApplicationSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teamRef': ObjectRefV1DtoToJSON(value.teamRef),
        'displayName': value.displayName,
    };
}

