/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HarborProjectLabelV1Dto } from './HarborProjectLabelV1Dto';
import {
    HarborProjectLabelV1DtoFromJSON,
    HarborProjectLabelV1DtoFromJSONTyped,
    HarborProjectLabelV1DtoToJSON,
} from './HarborProjectLabelV1Dto';
import type { HarborProjectRobotAccountV1Dto } from './HarborProjectRobotAccountV1Dto';
import {
    HarborProjectRobotAccountV1DtoFromJSON,
    HarborProjectRobotAccountV1DtoFromJSONTyped,
    HarborProjectRobotAccountV1DtoToJSON,
} from './HarborProjectRobotAccountV1Dto';
import type { HarborProjectWebhookV1Dto } from './HarborProjectWebhookV1Dto';
import {
    HarborProjectWebhookV1DtoFromJSON,
    HarborProjectWebhookV1DtoFromJSONTyped,
    HarborProjectWebhookV1DtoToJSON,
} from './HarborProjectWebhookV1Dto';

/**
 * Harbor Project specification
 * @export
 * @interface HarborProjectSpecV1Dto
 */
export interface HarborProjectSpecV1Dto {
    /**
     * Any user in Maersk can pull images from the project
     * @type {boolean}
     * @memberof HarborProjectSpecV1Dto
     */
    _public?: boolean;
    /**
     * Whether prevent the vulnerable images from running
     * @type {boolean}
     * @memberof HarborProjectSpecV1Dto
     */
    preventVul?: boolean;
    /**
     * If the vulnerability is high than severity defined here, the images can't be pulled
     * @type {string}
     * @memberof HarborProjectSpecV1Dto
     */
    severity?: HarborProjectSpecV1DtoSeverityEnum;
    /**
     * List of CVEs to allow
     * @type {Array<string>}
     * @memberof HarborProjectSpecV1Dto
     */
    allowedCVEs?: Array<string>;
    /**
     * Project labels
     * @type {Set<HarborProjectLabelV1Dto>}
     * @memberof HarborProjectSpecV1Dto
     */
    labels?: Set<HarborProjectLabelV1Dto>;
    /**
     * The robot accounts linked to the project
     * @type {Set<HarborProjectRobotAccountV1Dto>}
     * @memberof HarborProjectSpecV1Dto
     */
    robots?: Set<HarborProjectRobotAccountV1Dto>;
    /**
     * Webhooks definition for the project
     * @type {Set<HarborProjectWebhookV1Dto>}
     * @memberof HarborProjectSpecV1Dto
     */
    webhooks?: Set<HarborProjectWebhookV1Dto>;
}


/**
 * @export
 */
export const HarborProjectSpecV1DtoSeverityEnum = {
    None: 'NONE',
    Low: 'LOW',
    Medium: 'MEDIUM',
    High: 'HIGH',
    Critical: 'CRITICAL'
} as const;
export type HarborProjectSpecV1DtoSeverityEnum = typeof HarborProjectSpecV1DtoSeverityEnum[keyof typeof HarborProjectSpecV1DtoSeverityEnum];


/**
 * Check if a given object implements the HarborProjectSpecV1Dto interface.
 */
export function instanceOfHarborProjectSpecV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HarborProjectSpecV1DtoFromJSON(json: any): HarborProjectSpecV1Dto {
    return HarborProjectSpecV1DtoFromJSONTyped(json, false);
}

export function HarborProjectSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_public': !exists(json, 'public') ? undefined : json['public'],
        'preventVul': !exists(json, 'preventVul') ? undefined : json['preventVul'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'allowedCVEs': !exists(json, 'allowedCVEs') ? undefined : json['allowedCVEs'],
        'labels': !exists(json, 'labels') ? undefined : (new Set((json['labels'] as Array<any>).map(HarborProjectLabelV1DtoFromJSON))),
        'robots': !exists(json, 'robots') ? undefined : (new Set((json['robots'] as Array<any>).map(HarborProjectRobotAccountV1DtoFromJSON))),
        'webhooks': !exists(json, 'webhooks') ? undefined : (new Set((json['webhooks'] as Array<any>).map(HarborProjectWebhookV1DtoFromJSON))),
    };
}

export function HarborProjectSpecV1DtoToJSON(value?: HarborProjectSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'public': value._public,
        'preventVul': value.preventVul,
        'severity': value.severity,
        'allowedCVEs': value.allowedCVEs,
        'labels': value.labels === undefined ? undefined : (Array.from(value.labels as Set<any>).map(HarborProjectLabelV1DtoToJSON)),
        'robots': value.robots === undefined ? undefined : (Array.from(value.robots as Set<any>).map(HarborProjectRobotAccountV1DtoToJSON)),
        'webhooks': value.webhooks === undefined ? undefined : (Array.from(value.webhooks as Set<any>).map(HarborProjectWebhookV1DtoToJSON)),
    };
}

