/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HarborEventV1EventDataResourcesInnerDto
 */
export interface HarborEventV1EventDataResourcesInnerDto {
    /**
     * 
     * @type {string}
     * @memberof HarborEventV1EventDataResourcesInnerDto
     */
    tag: string;
    /**
     * 
     * @type {string}
     * @memberof HarborEventV1EventDataResourcesInnerDto
     */
    resourceUrl: string;
}

/**
 * Check if a given object implements the HarborEventV1EventDataResourcesInnerDto interface.
 */
export function instanceOfHarborEventV1EventDataResourcesInnerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tag" in value;
    isInstance = isInstance && "resourceUrl" in value;

    return isInstance;
}

export function HarborEventV1EventDataResourcesInnerDtoFromJSON(json: any): HarborEventV1EventDataResourcesInnerDto {
    return HarborEventV1EventDataResourcesInnerDtoFromJSONTyped(json, false);
}

export function HarborEventV1EventDataResourcesInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborEventV1EventDataResourcesInnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': json['tag'],
        'resourceUrl': json['resource_url'],
    };
}

export function HarborEventV1EventDataResourcesInnerDtoToJSON(value?: HarborEventV1EventDataResourcesInnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': value.tag,
        'resource_url': value.resourceUrl,
    };
}

