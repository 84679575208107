/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { UnmanagedPostgresDatabaseSpecV1Dto } from './UnmanagedPostgresDatabaseSpecV1Dto';
import {
    instanceOfUnmanagedPostgresDatabaseSpecV1Dto,
    UnmanagedPostgresDatabaseSpecV1DtoFromJSON,
    UnmanagedPostgresDatabaseSpecV1DtoFromJSONTyped,
    UnmanagedPostgresDatabaseSpecV1DtoToJSON,
} from './UnmanagedPostgresDatabaseSpecV1Dto';

/**
 * @type DatabaseSpecV1Dto
 * Database resource specification.
 * @export
 */
export type DatabaseSpecV1Dto = { databaseType: 'DATABASE_POSTGRES_UNMANAGED' } & UnmanagedPostgresDatabaseSpecV1Dto;

export function DatabaseSpecV1DtoFromJSON(json: any): DatabaseSpecV1Dto {
    return DatabaseSpecV1DtoFromJSONTyped(json, false);
}

export function DatabaseSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatabaseSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['databaseType']) {
        case 'DATABASE_POSTGRES_UNMANAGED':
            return {...UnmanagedPostgresDatabaseSpecV1DtoFromJSONTyped(json, true), databaseType: 'DATABASE_POSTGRES_UNMANAGED'};
        default:
            throw new Error(`No variant of DatabaseSpecV1Dto exists with 'databaseType=${json['databaseType']}'`);
    }
}

export function DatabaseSpecV1DtoToJSON(value?: DatabaseSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['databaseType']) {
        case 'DATABASE_POSTGRES_UNMANAGED':
            return UnmanagedPostgresDatabaseSpecV1DtoToJSON(value);
        default:
            throw new Error(`No variant of DatabaseSpecV1Dto exists with 'databaseType=${value['databaseType']}'`);
    }

}

