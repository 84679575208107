/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivityLogSpecV1SourceDto
 */
export interface ActivityLogSpecV1SourceDto {
    /**
     * type of the user
     * @type {string}
     * @memberof ActivityLogSpecV1SourceDto
     */
    type: ActivityLogSpecV1SourceDtoTypeEnum;
    /**
     * id of the user
     * @type {string}
     * @memberof ActivityLogSpecV1SourceDto
     */
    id: string;
}


/**
 * @export
 */
export const ActivityLogSpecV1SourceDtoTypeEnum = {
    Internal: 'INTERNAL',
    External: 'EXTERNAL',
    Unknown: 'UNKNOWN'
} as const;
export type ActivityLogSpecV1SourceDtoTypeEnum = typeof ActivityLogSpecV1SourceDtoTypeEnum[keyof typeof ActivityLogSpecV1SourceDtoTypeEnum];


/**
 * Check if a given object implements the ActivityLogSpecV1SourceDto interface.
 */
export function instanceOfActivityLogSpecV1SourceDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ActivityLogSpecV1SourceDtoFromJSON(json: any): ActivityLogSpecV1SourceDto {
    return ActivityLogSpecV1SourceDtoFromJSONTyped(json, false);
}

export function ActivityLogSpecV1SourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityLogSpecV1SourceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'id': json['id'],
    };
}

export function ActivityLogSpecV1SourceDtoToJSON(value?: ActivityLogSpecV1SourceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'id': value.id,
    };
}

