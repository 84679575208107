/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfraBrokerRevisionStatusV1ResourcesDto
 */
export interface InfraBrokerRevisionStatusV1ResourcesDto {
    /**
     * 1 CPU core = 1000 millicores
     * @type {number}
     * @memberof InfraBrokerRevisionStatusV1ResourcesDto
     */
    cpuMillicores?: number;
    /**
     * 
     * @type {number}
     * @memberof InfraBrokerRevisionStatusV1ResourcesDto
     */
    memoryMegabytes?: number;
}

/**
 * Check if a given object implements the InfraBrokerRevisionStatusV1ResourcesDto interface.
 */
export function instanceOfInfraBrokerRevisionStatusV1ResourcesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfraBrokerRevisionStatusV1ResourcesDtoFromJSON(json: any): InfraBrokerRevisionStatusV1ResourcesDto {
    return InfraBrokerRevisionStatusV1ResourcesDtoFromJSONTyped(json, false);
}

export function InfraBrokerRevisionStatusV1ResourcesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfraBrokerRevisionStatusV1ResourcesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpuMillicores': !exists(json, 'cpuMillicores') ? undefined : json['cpuMillicores'],
        'memoryMegabytes': !exists(json, 'memoryMegabytes') ? undefined : json['memoryMegabytes'],
    };
}

export function InfraBrokerRevisionStatusV1ResourcesDtoToJSON(value?: InfraBrokerRevisionStatusV1ResourcesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpuMillicores': value.cpuMillicores,
        'memoryMegabytes': value.memoryMegabytes,
    };
}

