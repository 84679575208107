/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DatabaseTypeV1Dto } from './DatabaseTypeV1Dto';
import {
    DatabaseTypeV1DtoFromJSON,
    DatabaseTypeV1DtoFromJSONTyped,
    DatabaseTypeV1DtoToJSON,
} from './DatabaseTypeV1Dto';

/**
 * Unmanaged Postgres Database specification
 * @export
 * @interface UnmanagedPostgresDatabaseBackupSpecV1Dto
 */
export interface UnmanagedPostgresDatabaseBackupSpecV1Dto {
    /**
     * 
     * @type {DatabaseTypeV1Dto}
     * @memberof UnmanagedPostgresDatabaseBackupSpecV1Dto
     */
    databaseType: DatabaseTypeV1Dto;
    /**
     * The timestamp when the backup was created
     * @type {Date}
     * @memberof UnmanagedPostgresDatabaseBackupSpecV1Dto
     */
    backupTime: Date;
    /**
     * The id of the database associated with the backup
     * @type {string}
     * @memberof UnmanagedPostgresDatabaseBackupSpecV1Dto
     */
    databaseId: string;
}

/**
 * Check if a given object implements the UnmanagedPostgresDatabaseBackupSpecV1Dto interface.
 */
export function instanceOfUnmanagedPostgresDatabaseBackupSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "databaseType" in value;
    isInstance = isInstance && "backupTime" in value;
    isInstance = isInstance && "databaseId" in value;

    return isInstance;
}

export function UnmanagedPostgresDatabaseBackupSpecV1DtoFromJSON(json: any): UnmanagedPostgresDatabaseBackupSpecV1Dto {
    return UnmanagedPostgresDatabaseBackupSpecV1DtoFromJSONTyped(json, false);
}

export function UnmanagedPostgresDatabaseBackupSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnmanagedPostgresDatabaseBackupSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'databaseType': DatabaseTypeV1DtoFromJSON(json['databaseType']),
        'backupTime': (new Date(json['backupTime'])),
        'databaseId': json['databaseId'],
    };
}

export function UnmanagedPostgresDatabaseBackupSpecV1DtoToJSON(value?: UnmanagedPostgresDatabaseBackupSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'databaseType': DatabaseTypeV1DtoToJSON(value.databaseType),
        'backupTime': (value.backupTime.toISOString()),
        'databaseId': value.databaseId,
    };
}

