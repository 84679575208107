/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines the environment class, only allowed value is GLOBAL
 * @export
 */
export const HarborProjectEnvironmentClassV1Dto = {
    Global: 'GLOBAL'
} as const;
export type HarborProjectEnvironmentClassV1Dto = typeof HarborProjectEnvironmentClassV1Dto[keyof typeof HarborProjectEnvironmentClassV1Dto];


export function HarborProjectEnvironmentClassV1DtoFromJSON(json: any): HarborProjectEnvironmentClassV1Dto {
    return HarborProjectEnvironmentClassV1DtoFromJSONTyped(json, false);
}

export function HarborProjectEnvironmentClassV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectEnvironmentClassV1Dto {
    return json as HarborProjectEnvironmentClassV1Dto;
}

export function HarborProjectEnvironmentClassV1DtoToJSON(value?: HarborProjectEnvironmentClassV1Dto | null): any {
    return value as any;
}

