/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HarborEventV1EventDataRepositoryDto } from './HarborEventV1EventDataRepositoryDto';
import {
    HarborEventV1EventDataRepositoryDtoFromJSON,
    HarborEventV1EventDataRepositoryDtoFromJSONTyped,
    HarborEventV1EventDataRepositoryDtoToJSON,
} from './HarborEventV1EventDataRepositoryDto';
import type { HarborEventV1EventDataResourcesInnerDto } from './HarborEventV1EventDataResourcesInnerDto';
import {
    HarborEventV1EventDataResourcesInnerDtoFromJSON,
    HarborEventV1EventDataResourcesInnerDtoFromJSONTyped,
    HarborEventV1EventDataResourcesInnerDtoToJSON,
} from './HarborEventV1EventDataResourcesInnerDto';

/**
 * 
 * @export
 * @interface HarborEventV1EventDataDto
 */
export interface HarborEventV1EventDataDto {
    /**
     * 
     * @type {Array<HarborEventV1EventDataResourcesInnerDto>}
     * @memberof HarborEventV1EventDataDto
     */
    resources: Array<HarborEventV1EventDataResourcesInnerDto>;
    /**
     * 
     * @type {HarborEventV1EventDataRepositoryDto}
     * @memberof HarborEventV1EventDataDto
     */
    repository: HarborEventV1EventDataRepositoryDto;
}

/**
 * Check if a given object implements the HarborEventV1EventDataDto interface.
 */
export function instanceOfHarborEventV1EventDataDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resources" in value;
    isInstance = isInstance && "repository" in value;

    return isInstance;
}

export function HarborEventV1EventDataDtoFromJSON(json: any): HarborEventV1EventDataDto {
    return HarborEventV1EventDataDtoFromJSONTyped(json, false);
}

export function HarborEventV1EventDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborEventV1EventDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resources': ((json['resources'] as Array<any>).map(HarborEventV1EventDataResourcesInnerDtoFromJSON)),
        'repository': HarborEventV1EventDataRepositoryDtoFromJSON(json['repository']),
    };
}

export function HarborEventV1EventDataDtoToJSON(value?: HarborEventV1EventDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resources': ((value.resources as Array<any>).map(HarborEventV1EventDataResourcesInnerDtoToJSON)),
        'repository': HarborEventV1EventDataRepositoryDtoToJSON(value.repository),
    };
}

