/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HarborProjectStateV1Dto } from './HarborProjectStateV1Dto';
import {
    HarborProjectStateV1DtoFromJSON,
    HarborProjectStateV1DtoFromJSONTyped,
    HarborProjectStateV1DtoToJSON,
} from './HarborProjectStateV1Dto';

/**
 * 
 * @export
 * @interface HarborProjectStatusV1Dto
 */
export interface HarborProjectStatusV1Dto {
    /**
     * 
     * @type {Date}
     * @memberof HarborProjectStatusV1Dto
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof HarborProjectStatusV1Dto
     */
    lastModifiedDate: Date;
    /**
     * 
     * @type {HarborProjectStateV1Dto}
     * @memberof HarborProjectStatusV1Dto
     */
    stateCode: HarborProjectStateV1Dto;
    /**
     * Human readable description of the `stateCode`
     * @type {string}
     * @memberof HarborProjectStatusV1Dto
     */
    stateMessage: string;
}

/**
 * Check if a given object implements the HarborProjectStatusV1Dto interface.
 */
export function instanceOfHarborProjectStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "lastModifiedDate" in value;
    isInstance = isInstance && "stateCode" in value;
    isInstance = isInstance && "stateMessage" in value;

    return isInstance;
}

export function HarborProjectStatusV1DtoFromJSON(json: any): HarborProjectStatusV1Dto {
    return HarborProjectStatusV1DtoFromJSONTyped(json, false);
}

export function HarborProjectStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarborProjectStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': (new Date(json['createdDate'])),
        'lastModifiedDate': (new Date(json['lastModifiedDate'])),
        'stateCode': HarborProjectStateV1DtoFromJSON(json['stateCode']),
        'stateMessage': json['stateMessage'],
    };
}

export function HarborProjectStatusV1DtoToJSON(value?: HarborProjectStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': (value.createdDate.toISOString()),
        'lastModifiedDate': (value.lastModifiedDate.toISOString()),
        'stateCode': HarborProjectStateV1DtoToJSON(value.stateCode),
        'stateMessage': value.stateMessage,
    };
}

