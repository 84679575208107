/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Database provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RevisionStatusV1InstancesInnerEventsInnerDto } from './RevisionStatusV1InstancesInnerEventsInnerDto';
import {
    RevisionStatusV1InstancesInnerEventsInnerDtoFromJSON,
    RevisionStatusV1InstancesInnerEventsInnerDtoFromJSONTyped,
    RevisionStatusV1InstancesInnerEventsInnerDtoToJSON,
} from './RevisionStatusV1InstancesInnerEventsInnerDto';

/**
 * 
 * @export
 * @interface RevisionStatusV1InstancesInnerDto
 */
export interface RevisionStatusV1InstancesInnerDto {
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionStatusV1InstancesInnerDto
     */
    state: RevisionStatusV1InstancesInnerDtoStateEnum;
    /**
     * 
     * @type {Array<RevisionStatusV1InstancesInnerEventsInnerDto>}
     * @memberof RevisionStatusV1InstancesInnerDto
     */
    events?: Array<RevisionStatusV1InstancesInnerEventsInnerDto>;
}


/**
 * @export
 */
export const RevisionStatusV1InstancesInnerDtoStateEnum = {
    Pending: 'PENDING',
    Healthy: 'HEALTHY',
    Failing: 'FAILING',
    Deleted: 'DELETED',
    Unknown: 'UNKNOWN'
} as const;
export type RevisionStatusV1InstancesInnerDtoStateEnum = typeof RevisionStatusV1InstancesInnerDtoStateEnum[keyof typeof RevisionStatusV1InstancesInnerDtoStateEnum];


/**
 * Check if a given object implements the RevisionStatusV1InstancesInnerDto interface.
 */
export function instanceOfRevisionStatusV1InstancesInnerDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function RevisionStatusV1InstancesInnerDtoFromJSON(json: any): RevisionStatusV1InstancesInnerDto {
    return RevisionStatusV1InstancesInnerDtoFromJSONTyped(json, false);
}

export function RevisionStatusV1InstancesInnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionStatusV1InstancesInnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'state': json['state'],
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(RevisionStatusV1InstancesInnerEventsInnerDtoFromJSON)),
    };
}

export function RevisionStatusV1InstancesInnerDtoToJSON(value?: RevisionStatusV1InstancesInnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'state': value.state,
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(RevisionStatusV1InstancesInnerEventsInnerDtoToJSON)),
    };
}

